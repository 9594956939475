import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@material-ui/core/Modal";
import Slider from "react-slick";
import {
  dateToDMY,
  FnpPageType,
  getFnpPageType,
  getProductDeliveryType,
  getShippingMethodsInStringAndCount,
  isDateTodayOrTomorrow,
  processDeliveryDate,
  sanitizeDateStringToDate,
  timeSlotStringAndCount,
} from "../../src/utils/common";
import CHECKOUT_CONSTANTS from "../../src/action-constants/checkout-constants";
import { removeItemBasedOnKey } from "../../src/utils/search-utils";
import ModalDateTimePicker from "../desktop/pdp/modal-date-time-picker";
import ShippingMethod from "../desktop/pdp/shipping-method";
import DeliveryCalender from "../desktop/pdp/calender";
import slickStyles from "../desktop/pdp/style/calendar-slider-slick.module.scss";
import { checkIsInternationalProduct } from "../../src/utils/checkout";
import TimeSlot from "../desktop/pdp/time-slot";
import { cleverTapCommonEvents } from "../../src/analytics/clevertapConstants";
import { CAKE_TYPE } from "../../src/utils/pdp-constants";
import callClevertapEvent from "../../src/analytics/clevertapUtility";
import { getShippingDetails } from "../../src/actions/checkout-actions";

/**
 * This method is used to render International Shipping selection and Delivery Calender
 *
 * @param {object} root0 it includes object from parent component
 * @param {Function} root0.onSelect to handle selection.
 * @param {object} root0.giftItem Gift item
 * @param {Function} root0.getPrice to handle price.
 * @param {Function} root0.onClose to close component.
 * @param {object} root0.cleverTapCartCommonData .
 * @param {boolean} root0.triggeredFromCart to track it's triggered from cart.
 * @returns {React.ReactElement} jsx for the International Shipping selection and Delivery Calender.
 */
function CheckoutInternationalDeliveryChangeDesktop({
  onSelect,
  giftItem,
  getPrice,
  onClose,
  cleverTapCartCommonData,
  triggeredFromCart,
}) {
  const sliderRef = useRef(null);
  const initialDate = sanitizeDateStringToDate(giftItem?.shippingDetails?.deliveryDate?.fullDeliveryDate) || null;
  const [open, setOpen] = useState(true);
  const [date, setDate] = useState(initialDate);
  const [showMultiOrderModal, setShowMultiOrderModal] = useState(false);
  const [isSameShippingSelected, setIsSameShippingSelected] = useState(false);
  const [isSameDateSelected, setIsSameDateSelected] = useState(false);

  const internationalShippingTypes = useSelector((state) => state.checkout?.internationalShippingTypes);
  const internationalDeliveryDates = useSelector((state) => state.checkout?.internationalDeliveryDates);
  const shippingDatesAndMethodsLoader = useSelector((state) => state.checkout?.shippingDatesAndMethodsLoader);
  const shippingEvents = useSelector((state) => state.checkout?.shippingDatesAndMethods?.shippingEvents);
  const deliveryDatesLoader = useSelector((state) => state.checkout?.deliveryDatesLoader);
  const { deliveryDates } = internationalDeliveryDates;
  const { shippingDetails, isShippingDetailsExists } = internationalShippingTypes;
  const priceSurgeDetails = useSelector((state) => state.checkout?.priceSurgeDetails);
  const productPriceByDate = useSelector((state) => state.productDetail?.productPriceByDate);
  const currency = useSelector((state) => state.appData?.selectedCurrency);
  const { catalogId } = useSelector((state) => state.appConfigs);
  const { selectedType } = useSelector((state) => state.pdpContext);
  const currencyCode = currency === "INR" ? "₹" : currency;
  const [calenderScreen, setCalenderScreen] = useState(false);
  const [deliveryMethod, setDeliveryMethodScreen] = useState(true);
  const [selectedSlotByUser, setSelectedSlotByUser] = useState({});
  const [userSelectedDate, setUserSelectedDate] = useState(null);
  const [domesticShippingDetails, setDomesticShippingDetails] = useState({});
  const [shippingViewed, setShippingViewed] = useState(false);

  const dispatch = useDispatch();

  const isHomeOrCategoryOrCartOrInfoPages =
    getFnpPageType() === FnpPageType.HOME ||
    getFnpPageType() === FnpPageType.CATEGORY ||
    getFnpPageType() === FnpPageType.CART_PREVIEW ||
    getFnpPageType() === FnpPageType.INFO;

  const { nextAvailableDateDiff } = shippingEvents || {};
  const productDeliveryType = getProductDeliveryType(giftItem?.primaryProductCategoryId);
  const deliveryDateFormat = giftItem?.shippingDetails?.deliveryDate?.fullDeliveryDate;
  const cleverTapData = {
    product_name: giftItem.productName,
    pid: giftItem.productId,
    product_category: giftItem.primaryProductCategoryId,
    product_price: giftItem.price,
    is_variant_available: triggeredFromCart ? null : giftItem.isVariant,
    is_egg: selectedType.toUpperCase() === CAKE_TYPE.WITH_EGG,
    delivery_type: productDeliveryType,
    pincode: giftItem.pincode,
    area: giftItem.deliveryLocation,
    system_pincode: giftItem.pincode,
    country: catalogId,
    city: giftItem.deliveryLocation,
    is_international: giftItem.isIntlCatalog,
    date_of_delivery: processDeliveryDate(deliveryDateFormat),
    delivery_shipping: giftItem.shippingDetails.shippingMethodName,
    delivery_shipping_fee: giftItem.shippingDetails.shippingPrice,
    delivery_slot: giftItem.shippingDetails.deliveryTimeSlot,
    next_available_date_difference: nextAvailableDateDiff,
    ...cleverTapCartCommonData,
  };

  useEffect(() => {
    removeItemBasedOnKey("selectedDates");
    removeItemBasedOnKey("selectedDate");
    const isInternational = checkIsInternationalProduct(giftItem?.mainproduct);
    const payload = {
      pincode: giftItem?.mainproduct?.pincode,
      productId: giftItem?.mainproduct?.productId,
      isInternational,
    };
    dispatch({ type: CHECKOUT_CONSTANTS.GET_INTL_SHIPPING, payload });
  }, [dispatch, giftItem.mainproduct, giftItem.mainproduct.pincode, giftItem.mainproduct.productId]);

  /**
   * This method is used to handle close delivery info screen
   *
   */
  const handleClose = () => {
    if (isHomeOrCategoryOrCartOrInfoPages) {
      const popupCloseCleverTapData = {
        area: giftItem.deliveryLocation,
        pincode: undefined,
        city: giftItem.deliveryLocation,
        ...cleverTapData,
      };
      callClevertapEvent(cleverTapCommonEvents.deliveryPopupClosed, popupCloseCleverTapData);
    }
    setOpen(false);
    onClose();
  };

  /**
   * This method handles next slide
   *
   */
  const gotoNext = () => {
    sliderRef.current.slickNext();
  };

  /**
   * This method is used to update the cartItem
   *
   * @param {object} newSelectedSlot .
   * @param {object} selectedDate date.
   */
  const updateCart = (newSelectedSlot, selectedDate) => {
    const data = {
      deliveryDate: dateToDMY(new Date(selectedDate)),
      itemIndex: giftItem?.cartItemIndex,
      shippingMethodId: selectedSlotByUser?.timeSlots[0]?.shipmentMethodId,
      shippingPrice: newSelectedSlot?.shippingPrice,
      timeSlotId: newSelectedSlot?.timeSlotId,
    };
    onSelect(data);
  };

  /**
   * This method is used to select date select
   *
   * @param {object} selectedDate date object.
   */
  async function handleDateSelect(selectedDate) {
    const newSelectedDate = new Date(selectedDate);
    const { isDateToday, isDateTomorrow } = isDateTodayOrTomorrow(newSelectedDate);
    if (isHomeOrCategoryOrCartOrInfoPages) {
      const dateSelectionCleverTapData = {
        is_same_date_selected: selectedDate.getDate() === initialDate.getDate(),
        is_same_delivery_selected: isSameShippingSelected,
        is_same_slot_selected: false,
        area: giftItem.deliveryLocation,
        pincode: undefined,
        city: giftItem.deliveryLocation,
        is_date_today: isDateToday,
        is_date_tomorrow: isDateTomorrow,
        ...cleverTapData,
      };
      setIsSameDateSelected(selectedDate.getDate() === initialDate.getDate());
      callClevertapEvent(cleverTapCommonEvents.deliveryDateSelected, dateSelectionCleverTapData);
    }
    selectedDate?.setHours(0, 0, 0, 0);
    setDate(selectedDate);
    const { productId, pincode } = giftItem?.mainproduct;
    const requestParams = {
      productId,
      pinCode: pincode,
      deliveryDate: dateToDMY(selectedDate, "/"),
      countryGeoId: giftItem?.countryGeoId,
    };
    const isInternational = checkIsInternationalProduct(giftItem?.mainproduct);
    dispatch({ type: CHECKOUT_CONSTANTS.SHIPPING_DETAILS_FETCH_START });
    const shippingResponse = await getShippingDetails(requestParams, isInternational);
    if (shippingResponse?.data?.isShippingDetailsExists) {
      const payload = shippingResponse?.data;
      dispatch({ type: CHECKOUT_CONSTANTS.SET_SHIPPING_DETAILS, payload });
      setDomesticShippingDetails(shippingResponse);
      gotoNext();
    }
  }

  /**
   * This method is used to select time slot
   *
   * @param {object} selectedSlot selected time slot object.
   */
  function handleTimeSlotSelect(selectedSlot) {
    if (isHomeOrCategoryOrCartOrInfoPages) {
      const slotCleverTapData = {
        is_same_date_selected: isSameDateSelected,
        is_same_delivery_selected: isSameShippingSelected,
        is_same_slot_selected: giftItem.shippingDetails.deliveryTimeSlotId === selectedSlot.timeSlotId,
        area: giftItem.deliveryLocation,
        pincode: undefined,
        city: giftItem.deliveryLocation,
        ...cleverTapData,
      };
      callClevertapEvent(cleverTapCommonEvents.deliverySlotSelected, slotCleverTapData);
    }
    updateCart(selectedSlot, date);
    handleClose();
  }

  /**
   * Tracks the event when the available domestic shipping methods are viewed.
   *
   */
  const shippingMethodsViewed = () => {
    const { methodsString, methodsCount } = getShippingMethodsInStringAndCount(shippingDetails);
    const deliveryShippingViewedData = {
      ...cleverTapData,
      available_delivery_shipping_methods: methodsString,
      available_delivery_shipping_count: methodsCount,
    };
    callClevertapEvent(cleverTapCommonEvents.deliveryShippingViewed, deliveryShippingViewedData);
  };

  /**
   * Tracks the event when the delivery time slots are viewed.
   */
  const timeSlotsViewed = () => {
    const availableSlotAndCount = timeSlotStringAndCount(selectedSlotByUser);
    const deliverySlotsViewedData = {
      ...cleverTapData,
      available_delivery_slot: availableSlotAndCount.timeSlotsString || null,
      available_delivery_slot_count: availableSlotAndCount.timeSlotCount || null,
    };

    callClevertapEvent(cleverTapCommonEvents.deliverySlotsViewed, deliverySlotsViewedData);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (shippingDetails && !shippingViewed) {
        shippingMethodsViewed();
        setShippingViewed(true);
      } else {
        setShippingViewed(false);
      }
    }, 200);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingDetails]);

  /**
   * Handles slide change based on the provided index.
   *
   * @param {number} index - The index of the slide being changed to.
   *
   * @returns {null} Returns null
   */
  const handleSlideChangeEventCall = (index) => {
    if (index === 2) {
      return timeSlotsViewed();
    }
    return null;
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    swipe: false,
    touchMove: false,
    className: slickStyles["slick-wrapper-calendar"],
    afterChange: (index) => {
      handleSlideChangeEventCall(index);
    },
  };

  /**
   * This method is used to to handle intel shipping method selection
   *
   * @param {object} selectedShipping selected shipping.
   */
  function handleIntlShippingMethod(selectedShipping) {
    if (isHomeOrCategoryOrCartOrInfoPages) {
      const shippingCleverTapData = {
        is_same_date_selected: false,
        is_same_delivery_selected: selectedShipping.shipmentMethodId === giftItem?.shippingDetails?.shippingMethodId,
        is_same_slot_selected: false,
        area: giftItem.deliveryLocation,
        pincode: undefined,
        city: giftItem.deliveryLocation,
        ...cleverTapData,
      };
      setIsSameShippingSelected(selectedShipping.shipmentMethodId === giftItem?.shippingDetails?.shippingMethodId);
      callClevertapEvent(cleverTapCommonEvents.deliveryShippingSelected, shippingCleverTapData);
    }
    setDeliveryMethodScreen(false);
    const shippingInfo = shippingDetails[selectedShipping.shipmentMethodId];
    if (selectedShipping.shipmentMethodId === giftItem?.shippingDetails?.shippingMethodId) {
      setUserSelectedDate(giftItem?.shippingDetails?.deliveryDate?.fullDeliveryDate);
    } else {
      setUserSelectedDate(null);
    }
    if (shippingInfo?.timeSlots?.length) {
      const [slot] = shippingInfo.timeSlots;
      const payload = {
        pincode: giftItem?.mainproduct?.pincode,
        productId: giftItem?.mainproduct?.productId,
        timeSlotId: slot?.timeSlotId,
        shipmentType: selectedShipping.shipmentMethodId,
      };
      setSelectedSlotByUser(shippingInfo);
      dispatch({ type: CHECKOUT_CONSTANTS.GET_INTERNATIONAL_DELIVERY_DATES, payload });
      setCalenderScreen(true);
    }
    gotoNext();
  }

  /**
   * This method handles previous slide
   *
   */
  const gotoPrev = () => {
    sliderRef.current.slickPrev();
  };

  /**
   * Handles loading of delivery method and delivery dates screen.
   *
   * @returns {boolean} The appropriate loader value.
   */
  function handleLoader() {
    if (calenderScreen) {
      return deliveryDatesLoader;
    }
    if (deliveryMethod) {
      return shippingDatesAndMethodsLoader;
    }
    return false;
  }

  /**
   * This functional component represents the international shipping slider .
   *
   * @returns {React.Fragment} main jsx for the international calender.
   */
  const internationalProductDelivery = () => {
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalDateTimePicker showLoader={handleLoader()} onClose={handleClose}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Slider {...settings} ref={sliderRef}>
            <div>
              <ShippingMethod
                gotoPrev={gotoPrev}
                selectedDeliveryDate={date}
                onSelect={handleIntlShippingMethod}
                shippingDetails={shippingDetails}
                isShippingDetailsExists={isShippingDetailsExists}
                checkIsInternationalProduct={checkIsInternationalProduct(giftItem?.mainproduct)}
                getPrice={getPrice}
                currencyCode={currencyCode}
                selectedShippingMethodId={giftItem?.shippingDetails?.shippingMethodId}
              />
            </div>
            <div>
              <DeliveryCalender
                gotoPrev={gotoPrev}
                showBackButton
                checkIsInternationalProduct={checkIsInternationalProduct(giftItem?.mainproduct)}
                onSelect={handleDateSelect}
                deliveryDates={deliveryDates}
                itemDesiredDeliveryDate={userSelectedDate}
                itemDesiredDeliveryDatesList={
                  giftItem?.isMultiOrderProduct ? giftItem?.shippingDetails?.selectedDatesList?.toString() : ""
                }
                productDetail={{
                  product: { ...giftItem?.mainproduct, isMultiOrderProduct: false, multiOrderProductDetails: {} },
                  priceSurgeDetails,
                  productPriceByDate,
                }}
                getPrice={getPrice}
                currencyCode={currencyCode}
                showMultiOrderModal={showMultiOrderModal}
                setShowMultiOrderModal={setShowMultiOrderModal}
              />
            </div>
            {domesticShippingDetails?.data?.isShippingDetailsExists && (
              <TimeSlot
                gotoPrev={gotoPrev}
                shippingMethod={domesticShippingDetails?.data?.shippingDetails}
                selectedDeliveryDate={date}
                onSelect={handleTimeSlotSelect}
                checkIsInternationalProduct={checkIsInternationalProduct(giftItem?.mainproduct)}
                getPrice={getPrice}
                currencyCode={currencyCode}
                shippingMethodId={selectedSlotByUser?.timeSlots[0]?.shipmentMethodId}
                selectedCurrency={selectedSlotByUser?.priceDetails}
                selectedShippingMethodName={selectedSlotByUser?.shippingMethodName}
              />
            )}
          </Slider>
        </ModalDateTimePicker>
      </Modal>
    );
  };

  return <>{internationalProductDelivery()}</>;
}

CheckoutInternationalDeliveryChangeDesktop.propTypes = {
  onSelect: PropTypes.func.isRequired,
  giftItem: PropTypes.objectOf(PropTypes.any).isRequired,
  getPrice: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  cleverTapCartCommonData: PropTypes.objectOf(PropTypes.any).isRequired,
  triggeredFromCart: PropTypes.bool,
};

CheckoutInternationalDeliveryChangeDesktop.defaultProps = {
  triggeredFromCart: false,
};

export default CheckoutInternationalDeliveryChangeDesktop;
