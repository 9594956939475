import { getCleverTapInstance } from "../../components/hooks/analytics/clevertap/useCleverTapSetup";
import { cleverTapCommonWords, cleverTapEvents } from "./clevertapConstants";
import {
  FnpPageType,
  getFnpPageType,
  isMobileDevice,
  removeEmptyNullAndUndefinedFromObject,
  fetchGeoLocation,
  getCleverTapID,
} from "../utils/common";
import triggerCustomWebSession from "./customWebSession";

/* TODO: Move this function to a common or global file,
   then remove its usage and delete it from src/analytics/customWebSession.js
 */
/**
 *
 * @returns {string} according to page type
 */
export const getPageNameForCleverTap = () => {
  switch (getFnpPageType()) {
    case FnpPageType.HOME:
      return cleverTapCommonWords.HOME;
    case FnpPageType.CATEGORY:
      return cleverTapCommonWords.PLP;
    case FnpPageType.CART_PREVIEW:
      return cleverTapCommonWords.CART;
    case FnpPageType.PRODUCT:
      return cleverTapCommonWords.PRODUCT;
    case FnpPageType.PLP_MICRO_SITE:
      return cleverTapCommonWords.MICROSITE;
    case FnpPageType.INFO:
      return cleverTapCommonWords.INFO;
    case FnpPageType.SEARCH:
      return cleverTapCommonWords.SEARCH;
    case FnpPageType.TESTIMONIAL:
      return cleverTapCommonWords.TESTIMONIAL;
    default:
      return "";
  }
};

/**
 * This is a utility method to call clevertap events
 *
 * @param {string} eventName - contains event name
 * @param {object} eventData - contains object to be shared in event
 * @param {string} eventType - contains event type
 */
async function callClevertapEvent(eventName, eventData, eventType = cleverTapEvents.common) {
  const isMobile = isMobileDevice();
  const cleverTapInstance = getCleverTapInstance();
  const cleverTapID = getCleverTapID();

  const city = await fetchGeoLocation();
  const enhancedEventData = {
    ...eventData,
    browsing_city: city,
    platform: isMobile ? "mobile" : "desktop",
    ctid: cleverTapID,
  };
  const filteredEventData = removeEmptyNullAndUndefinedFromObject(enhancedEventData);
  const triggerWebSession = triggerCustomWebSession(cleverTapInstance);
  triggerWebSession();

  if (cleverTapInstance) {
    try {
      switch (eventType) {
        case cleverTapEvents.common:
          cleverTapInstance.event.push(eventName, filteredEventData);
          break;
        case cleverTapEvents.userLogin:
          cleverTapInstance.onUserLogin.push(filteredEventData);
          break;
        default:
          cleverTapInstance.event.push(eventName, filteredEventData);
          break;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("CleverTap event push failed:", error);
    }
  } else {
    // eslint-disable-next-line no-console
    console.warn("CleverTap instance is not initialized.");
  }
}

export default callClevertapEvent;
