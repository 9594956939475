import { parseCookies, setCookie } from "nookies";
import { FnpPageType, getBrowserName, getFnpPageType, getOperatingSystem, parseQueryString } from "../utils/common";
import { cleverTapCommonEvents, cleverTapCommonWords } from "./clevertapConstants";

/**
 *
 * @returns {string} according to page type
 */
const getPageNameForCleverTap = () => {
  switch (getFnpPageType()) {
    case FnpPageType.HOME:
      return cleverTapCommonWords.HOME;
    case FnpPageType.CATEGORY:
      return cleverTapCommonWords.PLP;
    case FnpPageType.CART_PREVIEW:
      return cleverTapCommonWords.CART;
    case FnpPageType.PRODUCT:
      return cleverTapCommonWords.PRODUCT;
    case FnpPageType.PLP_MICRO_SITE:
      return cleverTapCommonWords.MICROSITE;
    case FnpPageType.INFO:
      return cleverTapCommonWords.INFO;
    case FnpPageType.SEARCH:
      return cleverTapCommonWords.SEARCH;
    case FnpPageType.TESTIMONIAL:
      return cleverTapCommonWords.TESTIMONIAL;
    default:
      return "";
  }
};

/**
 * @param {object} cleverTapInstance .
 * @returns {Function} triggerSession.
 */
export default function triggerCustomWebSession(cleverTapInstance) {
  let customSessionStartTrigger = false;

  return function triggerSession() {
    const { cleverTapWebSession } = parseCookies();
    if (!customSessionStartTrigger && !cleverTapWebSession) {
      setCookie(null, "cleverTapWebSession", "webSessionStarted", {
        maxAge: 20 * 60,
        path: "/",
      });
      const searchQuery = parseQueryString(window.location.search);
      const { utm_source: utmSource, utm_medium: utmMedium, utm_campaign: utmCampaign, gclid } = searchQuery;
      const payload = {
        page_name: getPageNameForCleverTap(),
        page_type: getPageNameForCleverTap(),
        browser: getBrowserName(),
        complete_url: window.location.href,
        clean_url: `${window.location.origin}${window.location.pathname}`,
        operating_system: getOperatingSystem(),
        utm_source_name: utmSource,
        utm_medium_name: utmMedium,
        utm_campaign_name: utmCampaign,
        gclid,
      };
      cleverTapInstance?.event?.push(cleverTapCommonEvents.sessionStart, payload);
      customSessionStartTrigger = true;
    }
  };
}
